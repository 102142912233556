import { synsDate } from "@/api/modules/dataScreen";

/**
 * 根据当前路由获取对应路由下的数据
 * *需要路由上下文
 * @param {*} nowPath 当前路径
 * @param {*} allPathList 路由列表
 * @returns 返回目标路由下的数据
 */
export const GetNowPath = function (nowPath, allPathList) {
    let nowPathData = null;
    allPathList.forEach((item) => {
        if (nowPath.toLowerCase().includes(item.path.toLowerCase())) {
            nowPathData = item;
            return;
        }
    });
    return nowPathData
}



/**
* 获取现在时间
*/
export const getNowTime = function () {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

/**
* 获取现在年份
*/
export const getNowYear = function () {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const formattedDate = `${year}`;
    return formattedDate;
}

/**
 * 获取时间（大屏）
 * @returns 
 */
export const getCurrentTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const date = now.getDate();
    const day = now.getDay();
    const weekDays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    const currentTime = `${year}年${month}月${date}日 ${weekDays[day]} ${hours}:${minutes}:${seconds}`;
    return currentTime;
}

/**
 * 持续请求通知后段缓存电站数据
 */
let Time = null; //定时器

export const ContinuousAcquisition = async (data) => {

    if (Time !== null) {
        clearTimeout(Time);
    }
    await synsDate(data);
    if (Time === "finish") {
        clearTimeout(Time);
        return
    }

    Time = setTimeout(() => {
        ContinuousAcquisition(data);
    }, 300000)
}

/**
 * 结束持续请求
 */
export const CloseContinuousAcquisition = () => {
    clearTimeout(Time);
    console.log("结束")
    Time = "finish";
}



/**
* 转换 对象属性有下划线的去掉后变为小驼峰
* @param {*} obj 
*/
export const convertToCamelCase = (obj) => {
    const newObj = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const newKey = key.replace(/_./g, match => match.charAt(1).toUpperCase());
            newObj[newKey] = obj[key];
        }
    }
    return newObj;
}

/**
 * 时间戳转日期格式
 * @param {*} timestamp 
 * @returns 
 */
export const timestampToDateTimeString = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

