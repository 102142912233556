import { getUsers, getWxUsers, getDeptList } from "@/api/modules/userControl";

//对数组对象新增属性
const addPropertyToObjects = (arr) => {
  arr.forEach((obj) => {
    obj.action = false;
    if (obj.childrenRole && Array.isArray(obj.childrenRole)) {
      addPropertyToObjects(obj.childrenRole);
    }
  });
  return arr;
};

const state = {
  //选中的组织与角色
  selectedOR: {
    organization: "",
    role: "",
  },
  //EMS用户
  emsUserList: [],
  //微信用户
  wxUserList: [],
  //分页
  pagination: {
    pageSize: 20,
    total: 0,
    pageNum: 1,
  },
  //用户类型
  userType: 1,
  //表格配置
  tableInfo: {
    Loading: false,
    title: "",
    userTotal: 0,
    deptName: "",
  },
  //组织
  organization: [],
  //第一次点击到父菜单
  parentMenus: null,
};

const mutations = {
  SET_PARENT_MENUS(state, data) {
    state.parentMenus = data;
  },
  REMAKE_SELECTED_O_R(state) {
    state.selectedOR = {
      organization: "",
      role: "",
    };
    state.organization = addPropertyToObjects(state.organization);
  },
  SET_SELECT_ORGANIZATION(state, data) {
    state.selectedOR.organization = data;
  },
  SET_SELECT_ROLE(state, data) {
    state.selectedOR.role = data;
  },
  SET_ORGANIZATION(state, data) {
    state.organization = data;
  },
  SET_EMS_USER_LIST(state, data) {
    state.emsUserList = data;
  },
  SET_PAGINATION(state, data) {
    state.pagination = data;
  },
  SET_WX_USER_LIST(state, data) {
    state.wxUserList = data;
  },
  CHANGE_PAGE_SIZE(state, data) {
    state.pagination.pageSize = data;
  },
  CHANGE_PAGE_CURRENT(state, data) {
    state.pagination.pageNum = data;
  },
  CHANGE_USER_TYPE(state, data) {
    state.userType = data;
  },
  CHANGE_TABLE_TITLE(state, data) {
    state.tableInfo.title = data;
  },
  CHANGE_TABLE_DEPT_TITLE(state, data) {
    state.tableInfo.deptName = data;
  },
  CHANGE_TABLE_LOADING(state, data) {
    state.tableInfo.Loading = data;
  },
  CHANGE_TABLE_TOTAL(state, data) {
    state.tableInfo.userTotal = data;
  },
  CLOSE_ALL_DATA(state){
    state.selectedOR = {
      organization: "",
      role: "",
    };
    state.emsUserList = [];
    state.wxUserList = [];
    state.pagination = {
      pageSize: 20,
      total: 0,
      pageNum: 1,
    };
    state.userType = 1;
    state.tableInfo = {
      Loading: false,
      title: "",
      userTotal: 0,
      deptName: "",
    };
    state.organization = [];
    state.parentMenus = null;
  }
};
const actions = {
  //查询所有组织
  async getOrganization({ commit }) {
    const result = await getDeptList();
    if (
      result &&
      result.code === 200 &&
      result.data &&
      result.data.length > 0
    ) {
      let ARR = addPropertyToObjects(result.data);
      commit("SET_ORGANIZATION", ARR);
    } else {
      commit("SET_ORGANIZATION", []);
    }
  },
  //获取EMS所有用户
  async GetEMSUsers({ commit }, param) {
    commit("CHANGE_TABLE_LOADING", true);
    const result = await getUsers(param);
    if (
      result &&
      result.code === 200 &&
      result.data &&
      result.data.list.length > 0
    ) {
      commit("SET_EMS_USER_LIST", result.data.list);
      const { pageSize, total, pageNum } = result.data;
      commit("SET_PAGINATION", { pageSize, total, pageNum });
      commit("CHANGE_TABLE_TOTAL", total);
    } else {
      commit("SET_EMS_USER_LIST", []);
      commit("SET_PAGINATION", {
        pageSize: 20,
        total: 0,
        pageNum: 1,
      });
      commit("CHANGE_TABLE_TOTAL", 0);
    }
    commit("CHANGE_TABLE_LOADING", false);
  },
  //获取微信用户
  async GetWxUsers({ commit }, param) {
    commit("CHANGE_TABLE_LOADING", true);
    const result = await getWxUsers(param);
    if (
      result &&
      result.code === 200 &&
      result.data &&
      result.data.list.length > 0
    ) {
      commit("SET_WX_USER_LIST", result.data.list);
      const { pageSize, total, pageNum } = result.data;
      commit("SET_PAGINATION", { pageSize, total, pageNum });
      commit("CHANGE_TABLE_TOTAL", total);
    } else {
      commit("SET_WX_USER_LIST", []);
      commit("SET_PAGINATION", {
        pageSize: 20,
        total: 0,
        pageNum: 1,
      });
      commit("CHANGE_TABLE_TOTAL", 0);
    }
    commit("CHANGE_TABLE_LOADING", false);
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
