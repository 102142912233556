
import requests from "@/utils/severConfig";

//查询电站列表回收率
export const getHealthList = () => requests({
    url: `/health/getHealthList`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询发电效率偏差
export const getEfficiencyCompare = (data) => requests({
    url: `/health/getEfficiencyCompare?${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});
