import Vue from 'vue'
import App from './App.vue'


/* ECharts */
import "@/plugins/echarts";

/**
 * elementUI
 */
// import elementUI from '@/plugins/element-ui.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//message一次只能弹出一个
import { message } from './utils/elMessage';
Vue.prototype.$message = message;

//router
import router from '@/router';
import "@/router/config";

//store
import store from "@/store";

/* 引入重制样式 */
import "@/assets/style/reset.css";

/**权限指令 */
import "@/utils/v-permission";

//轮播表
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)



//关闭生产提示
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this//安装全局事件总线
  }
}).$mount('#app')
