//存储token
export const setToken = (token) => {
    localStorage.setItem("Authorization", token);
};
//获取token
export const getToken = () => {
    return localStorage.getItem("Authorization");
};

//清除本地存储的token
export const removeToken = () => {
    localStorage.removeItem("Authorization");
}
