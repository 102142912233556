const state = {
  cancelTokenList: [],
};

const mutations = {
  addCancelToken(state, payload) {
    const maxCancelTokenNum = 15; // 最大存cancelToken条数
    if (state.cancelTokenList.length >= maxCancelTokenNum) {
      state.cancelTokenList.shift();
    }
    state.cancelTokenList.push(payload);
  },
  deleteCancelToken(state, payload) {
    state.cancelTokenList.splice(payload, 1);
  },
};

export default {
  state,
  mutations,
};
