
import requests from "@/utils/severConfig";

//查询用户电站ID
export const getStationIds = () => requests({
    url: `/station/getStationIds`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询电量，装机总量，报警数量，电站数量
export const getDateCount = (data) => requests({
    url: `/bigScreen/getDateCount?stationIds=${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询尖峰平谷比例统计图（总）
export const getPeaksAndValleys = (data) => requests({
    url: `/bigScreen/getPeaksAndValleys?stationIds=${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询近期发电情况 
export const getPowerLastList = (data) => requests({
    url: `/bigScreen/getPowerLastList?stationIds=${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询自用率
export const getSelfUseRate = (data) => requests({
    url: `/bigScreen/getSelfUseRate?stationIds=${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询电站报警离线数量
export const getStationCountList = (data) => requests({
    url: `/bigScreen/getStationCountList?stationIds=${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询电站报警离线数量
export const getHikvision = (data) => requests({
    url: `/bigScreen/getHikvision?stationIds=${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//故障列表、电站经纬度信息、节能减排
export const getFaultEtcList = (data) => requests({
    url: `/bigScreen/getFaultEtcList?stationIds=${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//同步缓存数据
export const synsDate = (data) => requests({
    url: `/bigScreen/synsDate?stationIds=${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询光伏发电预测情况
export const getPowerForecast = (data) => requests({
    url: `bigScreen/getPowerForecast?stationIds=${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});