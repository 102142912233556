import { login, logout } from "@/api/modules/system";
import { setToken, getToken, removeToken } from '@/utils/token';
import {setData,getData,removeData} from "@/utils/localStorageData";
import {resetRouter} from "@/router";
import {CloseContinuousAcquisition} from "@/utils/common";
const state = {
    Authorization: getToken(), //token
    nikeName: getData("NikeName") || "user", //用户名称
    userId: getData("UserId") || "", //用户ID
    isRouter: false, //是否已添加动态路由
};

const mutations = {
    USER_INFO(state, data) {
        state.Authorization = data.Authorization;
        state.nikeName = data.nikeName;
        state.userId = data.userId;
    },
    CLEAR_USER_INFO(state) {
        state.Authorization = "";
        state.nikeName = "";
        state.userId = "";
        state.isRouter = false;
    },
    //刷新存储的token
    SET_TOKEN(state) {
        state.Authorization = getToken();
    },
    SET_ROUTER(state, data) {
        state.isRouter = data;
    }
};
const actions = {
    /**
     * 登陆业务
     */
    async userLogin({ commit }, data) {

        let result = await login(data);

        if (!result) {
            return "登录异常，请联系管理员，或刷新页面重试！"
        }

        if (result.code === 401) {
            return result.message;
        }

        if (result.code === 200) {
            commit('USER_INFO', result.data);
            setToken(result.data.Authorization);
            setData("NikeName",result.data.nikeName);
            setData("UserId",result.data.userId);
            return 'succeed';
        }

    },
    /**
     * 退出登录
     */
    async userLogout() {
        let result = await logout();
        this.commit("CLEAR_STATION_DATA")
        this.commit("CLEAR_USER_INFO");
        removeData("NikeName"); //清除用户名
        removeData("UserId"); //清除用户id
        /**删除token */
        removeToken();
        //清除路由
        resetRouter();
        CloseContinuousAcquisition();
        if (!result) {
            console.log("退出异常！")
            return
        }
        
        if (result.code == 200) {
            return [true, '退出登录成功!'];
        } else {          
            return [false, result];
        }
        
    },
    /**
     * 退出登录（无权限）
     * @param {*} param0 
     * @returns 
     */
    async userLogoutPermission() {
        await logout();
        this.commit("CLEAR_STATION_DATA")
        this.commit("CLEAR_USER_INFO");
        removeData("NikeName"); //清除用户名
        /**删除token */
        removeToken();
        
    },
};

const getters = {

};

export default {
    state,
    mutations,
    actions,
    getters,
};
