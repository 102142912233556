/**默认路由 */
export default [
  {
    name: "消息通知",
    path: "/messageNotification",
    param: {
      MenusShow: false,
    },
    children: [{ name: "电站申请", path: "plantApplication" }],
  },
];
