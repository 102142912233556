
//存储数据
export const setData = (name,data) => {
    localStorage.setItem(name, data);
};
//获取数据
export const getData = (name) => {
    return localStorage.getItem(name);
};

//清除本地存储
export const removeData = (name) => {
    localStorage.removeItem(name);
}
