const state = {
  //分组id
  groupId: null,
  //电站id
  station: null,
  //当前电站id组
  stations: [],
  //当前需要搜索获取的类型
  typeGet: "station",
  //是否刷新列表 用于分组合并时刷新
  upData: false,
};

const mutations = {
  SET_GROUP_ID(state, data) {
    state.groupId = data;
  },
  SET_STATION_ID(state, data) {
    state.station = data;
  },
  SET_STATIONS_ID(state, data) {
    state.stations = data;
  },
  SET_TYPE_GET(state, data) {
    state.typeGet = data;
  },
  closeElectricityBillData(state) {
    state.groupId = null;
    state.station = null;
    state.stations = [];
    state.typeGet = "station";
  },
  electricityBill_UpData(state, data) {
    state.upData = data;
  },
};
const actions = {};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
