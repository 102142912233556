<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
.el-picker-panel.hide-time {
  .el-button--text.el-picker-panel__link-btn {
    display: none;
  }
}
</style>
