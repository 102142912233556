import requests from "@/utils/severConfig";

//验证码
export const code = (data) => requests({
    url: `/user/code?time=${data}`, 
    method: 'get', 
    responseType: 'blob' 
});

//登录
export const login = (data) => requests({
    url: '/user/login', 
    data, 
    method: 'post', 
});

//登出
export const logout = () => requests({ 
    url: '/user/logout', 
    method: 'get', 
});

//获取滑块验证的图片
export const getCodeImg = (data) => requests({ 
    url: '/user/captcha', 
    data, 
    method: 'post' 
});

//查询用户菜单信息
export const mainMenus = () => requests({
    url: `sysMenu/mainMenus`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询操作日志
export const getOperationList = (data) => requests({
    url: `operation/getOperationList?${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//删除操作日志
export const delOperation = (data) => requests({
    url: `operation/delOperation?operationIds=${data}`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//文件上传
export const fileUpload = (data) => requests({
    url: `uploadImg/fileUpload`,
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//文件删除
export const deleteFile = (data) => requests({
    url: `uploadImg/delete?fileName=${data}`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//根据ID查询菜单信息
export const getMenus = (data) => requests({
    url: `sysMenu/getMenus?roleId=${data}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//添加角色菜单
export const addRoleMenu = (data) => requests({
    url: `sysRoleMenu/addRoleMenu?${data}`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询省
export const getProvinceList = () => requests({
    url: `provincedef/getProvinceList`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询系统通知
export const getNoticeList = (keyword, pageNum, pageSize, id = '') => requests({
    url: `notice/getNoticeList?keyword=${keyword}&pageNum=${pageNum}&pageSize=${pageSize}&id=${id}`,
    method: 'get',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//删除系统通知消息
export const deleteNotice = (data) => requests({
    url: `notice/deleteNotice?noticeIds=${data}`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//新增系统通知消息
export const addNotice = (data) => requests({
    url: `notice/addNotice`,
    method: 'post',
    data
});

//修改系统通知消息
export const updateNotice = (data) => requests({
    url: `notice/updateNotice`,
    method: 'post',
    data
});

//发布小程序系统通知消息(不传为发送全部)
export const addRead = (systemId,wxIds) => requests({
    url: `notice/addRead?systemId=${systemId}&wxIds=${wxIds}`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//查询未读系统消息
export const getSystemRead = (userId) => requests({
    url: `read-pc/getSystemRead?userId=${userId}`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

//读消息系统消息
export const readMessage = (readId) => requests({
    url: `read-pc/readMessage?readId=${readId}`,
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});