import router from "./index";
import store from "@/store";
import { getToken } from "@/utils/token";

/**判断用户是否登录 */
const checkIfUserIsLoggedIn = function () {
  if (getToken()) {
    return true;
  } else {
    return false;
  }
};

/**
 * 后置路由守卫
 */
router.beforeEach((to, from, next) => {
  //不需要登录检查
  if (
    to.path === "/QuestionnaireSurvey" ||
    to.path === "/QuestionnaireSurvey/index"
  ) {
    next();
    return;
  }

  const isLoggedIn = checkIfUserIsLoggedIn(); //检查用户是否已经登录

  //判断用户是否前往登录页
  if (to.path === "/" || to.path === "/login") {
    // 用户已登录
    if (isLoggedIn) {
      //跳转主页
      next("/station");
      // next();
    } //用户未登录
    else {
      //放行让用户跳转登录页
      next();
    }
  } //用户不前往登录页
  else {
    // 如果用户已经登录，则继续跳转到目标页面
    if (isLoggedIn) {
      //用户已登录，判断是否已动态添加路由
      //已添加路由
      if (store.state.user.isRouter) {
        //虽已添加路由，但防止用户刷新 路由丢失 重新再获取路由信息
        if (!store.state.menuStore.AllMenusPath.length > 0) {
          let result = store.dispatch("GetMenuList");
          result.then(() => {
            next({
              path: to.fullPath, //因为刚添加完路由所以让如有重新走一遍
            });
          });
        }
        next();
      } //未添加路由
      else {
        let result = store.dispatch("GetMenuList");
        result.then(() => {
          next({
            path: to.fullPath, //因为刚添加完路由所以让如有重新走一遍
          });
        });
      }
    } else {
      // 如果用户没有登录，则跳转到登录页
      next("/login");
    }
  }
});

/**
 * 前置路由守卫
 */
router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
})