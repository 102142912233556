export default [
  {
    name: "数据大屏",
    path: "/dataScreen",
    icon: "icon-shujudaping",
    component: () => import("@/views/LargeDataScreen"),
    meta: {
      title: "数据大屏",
    },
  },
  {
    name: "运维工单",
    path: "/workorder",
    redirect: "/workorder/workorderTask",
    icon: "icon-yuanquyunwei",
    component: () => import("@/layout/main"),
    children: [
      {
        name: "所有任务",
        path: "workorderTask",
        component: () => import("@/views/workorderManagement"),
        meta: {
          title: "运维工单",
        },
      },
      {
        name: "我发起的",
        path: "initiateWorkorder",
        component: () => import("@/views/workorderManagement"),
        meta: {
          title: "运维工单",
        },
      },
      {
        name: "我的执行",
        path: "executeWorkorder",
        component: () => import("@/views/workorderManagement"),
        meta: {
          title: "运维工单",
        },
      },
    ],
  },
  {
    name: "电站运营",
    path: "/powerStationOperation",
    redirect: "/powerStationOperation/operationMonitorin",
    icon: "icon-yunyingguanli",
    component: () => import("@/layout/main"),
    children: [
      {
        name: "运营监控",
        path: "operationMonitorin",
        component: () => import("@/views/powerStationOperation"),
        meta: {
          title: "电站运营",
        },
      },
    ],
  },
  {
    name: "电费管理",
    path: "/electricityBill",
    redirect: "/electricityBill/electricity",
    icon: "icon-zhangdan",
    component: () => import("@/layout/main"),
    children: [
      {
        name: "读数单",
        path: "ammeterValue",
        component: () => import("@/views/ElectricityBill/ammeterValue"),
        meta: {
          title: "电费管理",
        },
      },
      {
        name: "电价单",
        path: "electrovalence",
        component: () => import("@/views/ElectricityBill/electrovalence"),
        meta: {
          title: "电费管理",
        },
      },
      {
        name: "电费单",
        path: "electricity",
        component: () => import("@/views/ElectricityBill/electricity"),
        meta: {
          title: "电费管理",
        },
      },
    ],
  },
  {
    name: "菜单管理",
    path: "/menuManagement",
    redirect: "/menuManagement/menuManagementIndex",
    icon: "icon-caidan",
    component: () => import("@/layout/main"),
    children: [
      {
        name: "菜单主页",
        path: "menuManagementIndex",
        component: () => import("@/views/menuManagement"),
        meta: {
          title: "菜单管理",
        },
      },
    ],
  },
  {
    name: "账号管理",
    path: "/userAccount",
    redirect: "/userAccount/userAccountIndex",
    icon: "icon-zhanghaoquanxianguanli",
    component: () => import("@/layout/main"),
    children: [
      {
        name: "用户管理",
        path: "userAccountIndex",
        component: () => import("@/views/UserManagement"),
        children: [
          {
            name: "新建用户",
            path: "addUser",
          },
          {
            name: "EMS编辑用户",
            path: "editUser",
          },
          {
            name: "用户绑定组织",
            path: "UserbindingOrganization",
          },
          {
            name: "删除用户",
            path: "delUser",
          },
          {
            name: "重制密码",
            path: "redoPassword",
          },
          {
            name: "用户绑定电站",
            path: "userBindingStation",
          },
          {
            name: "用户解绑电站",
            path: "userUnbindStation",
          },
        ],
        meta: {
          title: "账户管理",
        },
      },
      {
        name: "微信用户管理",
        path: "wxUserManage",
        meta: {
          title: "账户管理",
        },
        children: [
          {
            name: "绑定电站",
            path: "wxBindingStation",
          },
          {
            name: "解绑电站",
            path: "wxUnbundleStation",
          },
          {
            name: "WX编辑用户",
            path: "wxEditUser",
          },
        ],
      },
      {
        name: "组织管理",
        path: "organizationManage",
        meta: {
          title: "账户管理",
        },
        children: [
          {
            name: "新建组织",
            path: "addOrganization",
          },
          {
            name: "编辑组织",
            path: "editOrganization",
          },
          {
            name: "删除组织",
            path: "delOrganization",
          },
          {
            name: "添加角色",
            path: "addRole",
          },
          {
            name: "删除角色",
            path: "delRole",
          },
          {
            name: "编辑角色",
            path: "editRole",
          },
        ],
      },
    ],
  },
  {
    name: "权限管理",
    path: "/permission",
    redirect: "/permission/permissionIndex",
    icon: "icon-quanxianguanli",
    component: () => import("@/layout/main"),
    children: [
      {
        name: "账户权限",
        path: "permissionIndex",
        component: () => import("@/views/AuthorityManagement"),
        meta: {
          title: "权限管理",
        },
      },
    ],
  },
  {
    name: "问卷调查",
    path: "/questionnaire",
    redirect: "/questionnaire/questionnaireManage",
    icon: "icon-wenjuan",
    component: () => import("@/layout/main"),
    children: [
      {
        name: "问卷管理",
        path: "questionnaireManage",
        component: () => import("@/views/QuestionnaireSurvey/admin"),
        meta: {
          title: "问卷管理",
        },
        children: [
          {
            name: "问卷类型",
            path: "QuestionnaireType",
            component: () =>
              import("@/views/QuestionnaireSurvey/admin/questionnaireList"),
            meta: {
              title: "问卷类型",
            },
            children: [
              {
                name: "删除问卷类型",
                path: "delQuestionnaireType",
              },
            ],
          },
          {
            name: "编辑问卷",
            path: "editQuestionnaire",
            meta: {
              title: "编辑问卷",
            },
            component: () =>
              import("@/views/QuestionnaireSurvey/admin/editQuestionnaire"),
          },
          {
            name: "问卷答案",
            path: "questionnaireAnswer",
            meta: {
              title: "问卷答案",
            },
            component: () =>
              import("@/views/QuestionnaireSurvey/admin/questionnaireAnswer"),
          },
          {
            name: "问卷统计",
            path: "questionnaireStatistics",
            meta: {
              title: "问卷统计",
            },
            component: () =>
              import(
                "@/views/QuestionnaireSurvey/admin/questionnaireStatistics"
              ),
          },
        ],
      },
    ],
  },
  {
    name: "系统消息",
    path: "/systemMessage",
    redirect: "/systemMessage/historyMessage",
    icon: "icon-xitongxiaoxi",
    component: () => import("@/layout/main"),
    children: [
      {
        name: "历史消息",
        path: "historyMessage",
        component: () => import("@/views/SystemMessage/historyMessage"),
        meta: {
          title: "历史消息",
        },
        children: [
          {
            name: "删除系统消息",
            path: "deleteSystemMessage",
          },
        ],
      },
      {
        name: "消息发起",
        path: "sendMessage",
        component: () => import("@/views/SystemMessage/sendMessage"),
        meta: {
          title: "消息发起",
        },
      },
    ],
  },
  {
    name: "系统日志",
    path: "/systemLog",
    redirect: "/systemlog/systemLogIndex",
    icon: "icon-xitongrizhi",
    component: () => import("@/layout/main"),
    children: [
      {
        name: "日志管理",
        path: "systemLogIndex",
        component: () => import("@/views/SystemLog"),
        meta: {
          title: "系统日志",
        },
        children: [
          {
            name: "删除系统日志",
            path: "deleteSystemLog",
            meta: {
              icon: "123",
            },
          },
          {
            name: "删除系统日志",
            path: "deleteSystemLog",
            meta: {
              icon: "123",
            },
          },
        ],
      },
    ],
  },
  {
    name: "个人信息",
    path: "/PersonalDetails",
    icon: "icon-quanxianguanli",
    component: () => import("@/views/PersonalDetails"),
    meta: {
      title: "个人信息",
    },
    param: {
      MenusShow: false,
    },
  },
];
