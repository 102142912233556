import requests from "@/utils/severConfig";

//根据用户查询角色部门
export const getRoleDept = (data) =>
  requests({
    url: `/sysUserRole/getRoleDept?userId=${data}`,
    method: "get",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//新增用户
export const addUsers = (data) =>
  requests({
    url: `/sysUser/addUsers`,
    method: "post",
    data,
  });

//批量删除用户(多ID使用逗号分隔)
export const delUsers = (data) =>
  requests({
    url: `/sysUser/delUsers`,
    method: "post",
    data: {
      userIds: data,
    },
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//查询所有用户
export const getUsers = (data) =>
  requests({
    url: `/sysUser/getUsers?${data}`,
    method: "get",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//根据ID查询用户信息
export const getUsersById = (data) =>
  requests({
    url: `/sysUser/getUsersById?userId=${data}`,
    method: "get",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//查询用户信息用于下拉框
export const getUsersCode = () =>
  requests({
    url: `/sysUser/getUsersCode`,
    method: "get",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//修改用户
export const updateUsers = (data) =>
  requests({
    url: `/sysUser/updateUsers`,
    method: "post",
    data,
  });

//查询所有微信用户
export const getWxUsers = (data) =>
  requests({
    url: `/wxuser/getWxUsers?${data}`,
    method: "get",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//修改微信用户
export const updateWxUsers = (data) =>
  requests({
    url: `/wxuser/updateWxUsers`,
    method: "post",
    data,
  });

//查询已绑定或未绑定微信的电站
export const getStationWxList = (data) =>
  requests({
    url: `/station-Bind/getStationWxList?${data}`,
    method: "get",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//微信用户批量绑定电站
export const addStationWx = (data) =>
  requests({
    url: `/station-Bind/addStationWx`,
    method: "post",
    data,
  });

//微信用户批量取消绑定电站
export const deleteStationWx = (data) =>
  requests({
    url: `/station-Bind/deleteStationWx`,
    method: "post",
    data,
  });

//查询所有部门
export const getDeptList = () =>
  requests({
    url: `/sysDept/getDeptList`,
    method: "get",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//新增部门
export const addDept = (data) =>
  requests({
    url: `/sysDept/addDept`,
    method: "post",
    data,
  });

//修改部门
export const updateDept = (data) =>
  requests({
    url: `/sysDept/updateDept`,
    method: "post",
    data,
  });

//批量删除部门(多ID使用逗号分隔)
export const delDept = (data) =>
  requests({
    url: `/sysDept/delDept`,
    method: "post",
    data,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//新增角色
export const addRole = (data) =>
  requests({
    url: `/sysRole/addRole`,
    method: "post",
    data,
  });

//删除部门下角色信息
export const delRoles = (data) =>
  requests({
    url: `/sysRole/delRoles`,
    method: "post",
    data,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//删除修改角色信息
export const updateRole = (data) =>
  requests({
    url: `/sysRole/updateRole`,
    method: "post",
    data,
  });

//添加角色用户
export const addUserRole = (data) =>
  requests({
    url: `/sysUserRole/addUserRole`,
    method: "post",
    data,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//查询已绑定未绑定电站信息
export const getBindStationList = (data) =>
  requests({
    url: `/station-user/getBindStationList?${data}`,
    method: "get",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//ems用户批量取消绑定电站
export const deleteStationUser = (data) =>
  requests({
    url: `/station-user/deleteStationUser`,
    method: "post",
    data,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//ems用户批量绑定电站
export const addStationUser = (data) =>
  requests({
    url: `/station-user/addStationUser`,
    method: "post",
    data,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

//修改密码
export const updatePassWord = (data) =>
  requests({
    url: `sysUser/updatePassWord?${data}`,
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
