/**主页菜单路径 */
import { setData, getData } from "@/utils/localStorageData";
import { mainMenus } from "@/api/modules/system";
import { RouterComparison } from "@/utils/RouterPermission";

import router from "@/router";

/*电站*/
import powerStation from "@/router/modules/powerStation";
/*系统*/
import system from "@/router/modules/system";
/*消息通知*/
import message from "@/router/modules/message";

let RouterMapGroup = [
  ...powerStation,
  ...system,
  ...message,
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "Notfound", // 404
  //   component: () => import("@/views/components/404.vue"),
  // },
];

const state = {
  MenusFold: getData("MenusFold") === "false" ? false : true, //主页菜单展开｜折叠状态
  AllMenusPath: [], //主页菜单数据
  MenusNow: [], //主页当前选中的菜单下的子菜单数据
  NavTitle: {
    //NavTitle组件数据
    title: "电站概览", //标题
  },
  NowMenusPath: "", //当前路径
};

const mutations = {
  SET_NOW_MENUS_PATH(state, path) {
    state.NowMenusPath = path;
  },
  SET_MENUS_ACTION(state, type) {
    state.MenusFold = type;
    setData("MenusFold", type);
  },
  SET_MENUS_NOW(state, data) {
    state.MenusNow = data;
  },
  SET_MENUS_OLD(state, data) {
    state.MenusOld = data;
  },
  SET_NAV_TITLE(state, data) {
    state.NavTitle = data;
  },
  SET_MENU_DATA(state, data) {
    state.AllMenusPath = data;
  },
  CLOSE_DATA(state) {
    state.AllMenusPath = [];
    state.MenusNow = [];
    state.NavTitle = {};
    state.NowMenusPath = "";
  },
};
const actions = {
  /**
   * 获取用户菜单信息
   * @param {*} param0
   */
  async GetMenuList({ commit }) {
    let out = null;
    let result = await mainMenus();
    if (result && result.code === 200) {
      let routerData = RouterComparison(result.data, RouterMapGroup);

      routerData.forEach((item, index) => {
        if (item.name === "权限管理") {
          routerData[index] = {
            name: "权限管理",
            path: "/permission",
            redirect: "/permission/permissionIndex",
            icon: "icon-quanxianguanli",
            component: () => import("@/layout/main"),
            children: [
              {
                name: "账户权限",
                path: "permissionIndex",
                component: () => import("@/views/AuthorityManagement"),
                meta: {
                  title: "权限管理",
                },
              },
            ],
          };
        }
      });

      commit("SET_MENU_DATA", routerData);

      routerData.forEach((item) => {
        router.addRoute(item.name, item);
      });
      out = true;
    } else {
      this.dispatch("userLogoutPermission");
    }
    this.commit("SET_ROUTER", true);
    return out;
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
