//axios
import axios from "axios";
import store from "@/store";
import { setToken, removeToken } from "@/utils/token";
import router from "@/router";
import { removeData } from "@/utils/localStorageData";
import { CloseContinuousAcquisition } from "@/utils/common";
import {AXIOS_BASE_URL,NO_TOKEN} from "@/config";
/**
 * 加载进度条动画
 */
import nprogress from "nprogress";
import "nprogress/nprogress.css";


//基础配置
let requests = axios.create({
    baseURL:AXIOS_BASE_URL,
    timeout: 50000
});


/**
 * 请求拦截
 */
requests.interceptors.request.use((config) => {

    //进度条动画
    nprogress.start();

    //不需要携带token的请求
    let v = NO_TOKEN.some(path => config.url.startsWith(path));
    if (!v) {
        config.headers.Authorization = store.state.user.Authorization;
    }

    
    return config;
});




/**
 * 响应拦截
 */
requests.interceptors.response.use(
    (res) => {

        //进度条动画
        nprogress.done();

        //动态刷新更新最新token并存储
        if (res.headers.authorization) {
            setToken(res.headers.authorization);
            store.commit('SET_TOKEN');
        }

        //用户认证失败
        if (res.data.code === 401 && router.currentRoute.path !== '/login') {
            console.log('我被退出了',res)
            removeToken();
            router.push("/login");
            removeData("NikeName"); //清楚用户名称
            CloseContinuousAcquisition()
        }

        //token过期 567
        if (res.data.code === 567) {
            console.log('token过期',res)
            removeToken();
            removeData("NikeName"); //清楚用户名称
            router.push("/login");
            
        }

        return res.data;
    },
    (err) => {

        nprogress.done();
        // 拦截axios错误
        if (err instanceof axios.Cancel) return
    
        if (err.response && err.response.status === 401) {
            console.log('我被退出了',err.response)
            removeToken();
            removeData("NikeName"); //清楚用户名称
            router.replace("/login"); 


        } else {
            console.log("抛出接口异常", err)
        }
    }
);

export default requests;
