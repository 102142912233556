/**
 * requiresAuth 是否需要登录访问 true | false
 */

export default [
    {
        name: "电站管理",
        path: "/station",
        redirect: "/station/overview",
        icon: "icon-equipment_PV_fi",
        component: () => import('@/layout/main'),
        children: [
            {
                name: "电站概览",
                path: "overview",
                component: () => import('@/layout/components/StationManageLayout'),
                meta: {
                    addStation: false,
                },
                children: [
                    {
                        name: "添加电站",
                        path: "addStation",
                    },
                    {
                        name: "删除电站",
                        path: "deleteStation",
                    },
                    {
                        name: "编辑电站",
                        path: "upDataStation",
                    },
                    {
                        name: "查看电站配置信息",
                        path: "readConfiguration",
                    },
                    {
                        name: "查看电站分组",
                        path: "stationGrouping",
                    }
                ]
            }
        ]
    },
    {
        name: "实时监控",
        path: "/monitoring",
        redirect: "/monitoring/overviewMonitoring",
        icon: "icon-supervision-full",
        component: () => import('@/layout/main'),
        children: [
            {
                name: "数据概览",
                path: "overviewMonitoring",
                component: () => import('@/views/Monitoring/Overview'),
            },
            {
                name: "数据监测",
                path: "dataMonitoring",
                component: () => import('@/views/Monitoring/ReportForms'),
            },
            {
                name: "告警管理",
                path: "warningManage",
                component: () => import('@/views/Monitoring/Warning'),
            },
            {
                name: "设备管理",
                path: "equipmentManage",
                component: () => import('@/views/Monitoring/Equipment'),
            },
            {
                name: "视频监控",
                path: "videoMonitor",
                component: () => import('@/views/Monitoring/VideoSurveillance'),
            },
        ]
    }

]