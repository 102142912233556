const state = {
  roleInfo: {
    id: "",
    userList: [],
  },
  tableLoad: false,
};

const mutations = {
  SET_ROLE_INFO(state, data) {
    state.roleInfo = data;
  },
  SET_TABLE_LOAD(state, data) {
    state.tableLoad = data;
  },
  ELIMINATE_DATA(state) {
    state.roleInfo = {
      id: "",
      userList: [],
    };
    state.tableLoad = false;
  },
};
const actions = {};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
