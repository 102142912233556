import Vue from "vue";

import store from "@/store";



/**
 * 检验元素权限属性，若有权限保留，无权限则删除
 */
Vue.directive("permission", {
  inserted:  function (el, binding) {
    const { value } = binding;
    let router = store.state.menuStore.AllMenusPath;
    let boolean = RouterComparison(router, value);
    if (boolean) {
      //有权限不做任何操作
    } //没有权限则删除标签
    else {
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
});

//ES6
/**
 * 对比是否包含权限
 * @param {*} allRouter
 * @param {*} value
 * @returns
 */
// const RouterComparison =  (allRouter, value) => {

//     return  allRouter.some(route =>
//         route.path === value ||
//         (route.children && route.children.length > 0 && RouterComparison(route.children, value))
//       );

// }

/**ES5 */
const RouterComparison = function (allRouter, value) {
  for (let i = 0; i < allRouter.length; i++) {
    if (allRouter[i].path === value) {
      return true;
    }
    if (allRouter[i].children && allRouter[i].children.length > 0) {
      if (RouterComparison(allRouter[i].children, value)) {
        return true; // 如果子路由中有匹配的，直接返回true
      }
    }
  }
  return false;
};
