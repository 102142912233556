/**默认路由 */
export default [
    {
        path: "/",
        component: () => import('@/views/Login')
    },
    {
        path: "/login",
        component: () => import('@/views/Login'),
    },
    //  问卷调查
    {
        path: "/QuestionnaireSurvey",
        component: () => import('@/views/QuestionnaireSurvey/clientSide/welcome'),
    },
    {
        path: "/QuestionnaireSurvey/index",
        component: () => import('@/views/QuestionnaireSurvey/clientSide/questionnaire'),
        meta:{           
            title:'客户满意度调研',
        },
    }
    
]