import Vuex from "vuex";
import Vue from "vue";
// import createPersistedstate from 'vuex-persistedstate';
Vue.use(Vuex);

/*公共数据*/
import menuStore from "./modules/menuStore";
/*用户数据*/
import user from "./modules/user";
/**电站数据 */
import station from "./modules/station";
/*用户管理 */
import userManagement from "./modules/userManagement";
/*请求管理 */
import axios from "./modules/axios";
/* 运营监控 */
import StationOperation from "./modules/StationOperation";
/*权限管理*/
import permission from "./modules/permission";
/*电费管理*/
import electricityBill from "./modules/electricityBill";

export default new Vuex.Store({
  modules: {
    menuStore,
    user,
    station,
    userManagement,
    axios,
    StationOperation,
    permission,
    electricityBill
  },
  // plugins: [
  //     createPersistedstate({
  //         key: 'erabbit-client-pc-store',
  //         paths: ['user']
  //     })
  // ]
});
