
/**
 * 路由对比
 * @param {Array} userRouter 用户路由
 * @param {Array} allRouter 本地路由
 * @returns 
 */
export const RouterComparison = function (userRouter, allRouter) {
  
    let RouterMap = [];
    if (!allRouter) {
        return
    }
    for (let i = 0; i < allRouter.length; i++) {
        const matchedUserRouter = userRouter.find(router => router.menu_name === allRouter[i].name);
       
        if (matchedUserRouter) {
            /* 2024-03-11 取消以perms来判断权限
            if (matchedUserRouter.perms && matchedUserRouter.perms !== "") {
                let perms = matchedUserRouter.perms.split(":");
                perms.forEach(v => {
                    if (allRouter[i].meta.hasOwnProperty(v)) {
                        allRouter[i].meta[v] = true;
                    }
                });
            }
            */
            RouterMap.push(allRouter[i]);
            if (matchedUserRouter.childMenu && matchedUserRouter.childMenu.length > 0) {
                allRouter[i].children = RouterComparison(matchedUserRouter.childMenu, allRouter[i].children);
            }else{
                if (RouterMap[i]) {
                    RouterMap[i].children = null
                }
            }
            
        }
    }
    // return allRouter;
    return RouterMap;
}
// export const RouterComparison = function (userRouter, allRouter) {

//     let RouterMap = [];
//     for (let i = 0; i < allRouter.length; i++) {


//         for (let j = 0; j < userRouter.length; j++) {
            
//             if (userRouter[j].menu_name === allRouter[i].name) {
//                 RouterMap.push(allRouter[i])
                
//                 if (userRouter[j].childMenu && userRouter[j].childMenu.length > 0) {
//                     allRouter[i].children = RouterComparison(userRouter[j].childMenu, allRouter[i].children);
//                 }else{
//                     if (RouterMap[i]) {
//                         RouterMap[i].children = null
//                     }
            
//                 }
//             }
//         }
        
//     }

//     return RouterMap;
// }