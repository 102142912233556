import VueRouter from "vue-router";
import Vue from "vue";
Vue.use(VueRouter);
//默认路由
import defaultRoute from './modules/default';

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }), //当切换路由时，页面滚动至top 0
    routes: defaultRoute,
});

/**
 * 清空路由
 * 重新 new Router 实例
 * 覆盖原动态添加的路由
 */
export function resetRouter() {
    const newRouter = new VueRouter({
        mode: 'hash',
        base: process.env.BASE_URL,
        scrollBehavior: () => ({ y: 0 }), //当切换路由时，页面滚动至top 0
        routes: defaultRoute,
    });
    router.matcher = newRouter.matcher;
}


//捕获调用push切换到同一路由时报错的异常。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


//捕获调用replace切换到同一路由时报错的异常。
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

export default router;