/*
 *图片拼接地址
 */
// export const IMAGE_URL = "http://192.168.2.88:8087/photovoltaic" //本地
export const IMAGE_URL = "http://power.inengxin.com/photovoltaic"; //线上

/*
 * axios 接口访问地址
 */
// export const AXIOS_BASE_URL = "http://192.168.2.88:8087/photovoltaic"; //本地地址
// export const AXIOS_BASE_URL = "http://power.inengxin.com/photovoltaic"; //线上地址
export const AXIOS_BASE_URL = "/photovoltaic"; //打包

/*
 * 文件预览地址
 */
export const FILE_PREVIEW_URL = "http://crm.inengxin.com:8012/onlinePreview?url="; //线上地址

/**
 * 运维工单角色id
 */
// export const TASK_ROLE_ID = 47; //线上
export const TASK_ROLE_ID = 52; //本地

/**
 * 不携带token 请求接口
 */
export const NO_TOKEN = [
  "/user/login",
  "/user/code",
  "/user/captcha",
  "/bigScreen/getDateCount",
  "/bigScreen/getPeaksAndValleys",
  "/bigScreen/getPowerLastList",
  "/bigScreen/getSelfUseRate",
  "/bigScreen/getStationCountList",
  "/bigScreen/getHikvision",
  "/bigScreen/getFaultEtcList",
  "/bigScreen/synsDate",
  "bigScreen/getPowerForecast",
  "/answers/getQuestionList",
  "/answers/addAnswers",
  "/pdf/electric-bill",
];
