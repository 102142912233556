import {getStationIds} from "@/api/modules/dataScreen";
import {ContinuousAcquisition} from "@/utils/common";
const state = {
    acquisitionPointId: undefined, //电站ID
    stationInfo: undefined, //电站详情
    upDataStation: 0, //0更新 1不更新
    StationList:[],//电站列表
    UserStationIds:"",//用户电站id列表
};

const mutations = {
    SET_USER_STATION_IDS(state,data){
        state.UserStationIds = data;
    },
    SET_ACQUISITION_POINT_ID(state, id) {
        state.acquisitionPointId = id;
    },
    STATION_INFO(state, data) {
        state.stationInfo = data;
    },
    upDataStation(state, type) {
        state.upDataStation = type;
    },
    CLEAR_STATION_DATA(state) {
        state.acquisitionPointId = undefined;
        state.stationInfo = undefined;
        state.upDataStation = 0;
        state.StationList = [];
    },
    SET_STATION_LIST(state,data){
        state.StationList = data;
    },
};

const actions = {
    async GetUserIds({commit}){
        let result = await getStationIds();
        if (result && result.code === 200) {
            if (result.data) {
                ContinuousAcquisition(result.data.join(","));
            }else{
                ContinuousAcquisition("");
            }
            
            commit("SET_USER_STATION_IDS",result.data);
        }else{
            ContinuousAcquisition("");
        }
    },
};

const getters = {

};

export default {
    state,
    mutations,
    actions,
    getters,
};
