import { getEfficiencyCompare } from "@/api/modules/operationMonitoring";
import { getNowYear } from "@/utils/common";

const state = {
  // 电站发电效率偏差
  EfficiencyDeviation: {
    time: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  //统计图日期
  year: getNowYear(),
  //图表加载动画
  chartLoading: false,
  //当前选择的电站采电ID
  clickStationId: null,
};

const mutations = {
  SET_EFFICIENCY_DEVIATION(state, data) {
    state.EfficiencyDeviation.value = data;
  },
  SET_CHART_LOADING(state, data) {
    state.chartLoading = data;
  },
  SET_YEAR(state, data) {
    state.year = data;
  },
  SET_CLICK_STATION_ID(state, data) {
    state.clickStationId = data;
  },
};
const actions = {
  //查询发电效率偏差
  async GetEfficiencyDeviation({ commit }, pointIds) {
    commit("SET_CHART_LOADING", true);
    let param = `pointIds=${pointIds}&year=${state.year}`;
    const result = await getEfficiencyCompare(param);

    let value = [];
    if (result && result.code === 200 && result.data) {
      if (result.data && result.data.length > 0) {
        for (let i = 0; i < result.data.length; i++) {
          value.push({
            name: result.data[i].stationName,
            data: supplementXData(result.data[i].list),
          });
        }
      } else {
        value = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      }
    }
    commit("SET_EFFICIENCY_DEVIATION", value);
    commit("SET_CHART_LOADING", false);
  },
};

//判断填补x轴数据
function supplementXData(data) {
  let mapData = state.EfficiencyDeviation.time;
  const result = mapData.map((v) => {
    const matchedItem = data.find((item) => item.month === v);
    return {
      value: matchedItem ? matchedItem.powerHour : 0,
      itemStyle: {
        color: matchedItem ? matchedItem.color : "#797979",
      },
    };
  });
  return result;
}

export default {
  state,
  mutations,
  actions,
};
